<template>
    <div class="cell" style="padding:10px"> 

        <div style="position: relative;height:70px;margin-bottom:15px">
                <div class="qlargecircularimage qheadercolour" style="position:absolute;left:0px;top:5px"><img style="height:55px;width:55px" src="/assets/icons/knowledge.png"/></div>
                   <div  style="padding-top:5px; padding-left:17px;padding-right: 4px">

                        <h1 class="qmajorheader qalignmiddle " v-html="langConv('Knowledge Base')"></h1>
                        <h2 class="qsubheader qalignmiddle qsmallfontplus1" v-html="langConv('Unbiased views on all things gambling')"></h2>
            
                   </div>
        </div>

   
       <searchcriteria  @criteria="configChanged" searchtype="dateandsearchonly" >  </searchcriteria>

        <!-- tabs here  -->

         <tabs @changed="tabChanged"  v-if="results.length>0" :tabtype="$tabtype.textbelowimage">
            <div v-for="(row, i2) in results" :key="i2" >
                <tab :title=row.Title :img=row.img :colour="row.colour" :id=i2 :description="row.D" :isactive=false></tab>  
            </div> 
        </tabs> 
    
        <knowledgeview view="full" :startdate="startdate" :enddate="enddate" :section="section" :specifictext="searchdesc"/> 
        
    </div>
</template>

<script>
import knowledgeview from '../components/components/knowledgeview.vue' 
import searchcriteria from '../components/components/searchcriteria.vue'
import tab from '../components/modified3rdpartycomponents/tab.vue'
import tabs from '../components/modified3rdpartycomponents/tabs.vue'

export default {
    name: "knowledgebase",
         data() {
            return {
                results: [],
                currenttab: 0,
                startdate: new Date("1977/1/1"),
                enddate: new Date("2099/1/1"),
                section: "all",
                searchdesc: ""     
            }
         },

    components: {
        knowledgeview,
        searchcriteria,
        tabs,
       tab
    },
    created() {
        this.getTabData();
    },

    methods: {
    
      getTabData: function() {

                let params = '[]';
                let url = process.env.VUE_APP_WEBAPI  + "FeatureTables.svc/RetrieveGeneric";
                let spname = "GetKnowledgeBaseTypes_NS";

                //get bet results for that bet
                this.newgenericresultset(url, params,spname).then(response => { this.results = response});
       },

        tabChanged: function(tab) {

            if (tab != this.currenttab) {
                 this.currenttab = tab
                 this.section = this.results[tab].Title    //.replace(" ","")
            }

        },

        configChanged: function (fromdate,todate,selectedusers,selectedstrategies,trendonly = false,individualbets = false,topn = 999,ascend=false, searchdesc) {
          
            this.startdate = fromdate;
            this.enddate = todate;
            this.searchdesc = searchdesc;
     

        }

    }


}
</script>

<style scoped>

</style>
