<!-- Vue component -->
<template>
    <div class="grid-y" style="position:relative;margin: 2px;">
        <div class="cell small-12 medium-cell-block" >
            <div :class="{loader: isloading}">

                <div :style="{display: isvisible}" v-for="(row,no) in infotabledata" :key="no">
                 

                        <div :class="{'qhighlight': no % 2}" class="qalignleft" v-if="view == 'brief'">
                            <div class="grid-x">
                            <div class="cell small-9 qsmallfontplus1">
                                <a :href="row.link" target="_blank" v-if="row.link!=null"> 
                                                     
                                    <span class="qborderlink qsmallfontplus1">{{row.title}}</span> 
                                         
                                </a>
                            </div>
                             
                            <div class="cell small-3 qsmallfont qgreylink">{{$displayDate(row.created,$dateformattype.daymonthtime)}}</div>
                         
                        </div>

                        <div  class="grid-x" v-on:click="showdetail(no)" >
                                <div class="cell small-10 qsmallfont">{{row.detail}}</div>
                                <div :id="'knowledge' + no" class="cell small-2" style="cursor: pointer;">▾</div>
                        </div>

                        <div :id="'knowledgedetail' + no" class="qsmallfont" style="display:none">
                            {{ row.detail }}
                        </div>

                        </div>

                        <!-- Full view of blog -->
                      
                            <!-- :class="{'qhighlight': no % 2}" -->
                            <div class="grid-x " > 
                               
                                <div class="cell shrink qlegendfont qhighlight text-center" style="height:26px;padding-right:10px">{{$displayDate(row.created,$dateformattype.daymonthtime)}}</div> 
                                <div class="cell auto">
                                    <div class="qsmallfontplus1 qhighlight" style="height:26px;padding-top:2px">{{row.title}}</div>
                                    <div class="qsmallfontplus" style="color:black;line-height:26px" v-html="row.detail"></div>
                                </div>
                                <!-- <div class="cell shrink qhighlight" style="padding-right:3px;height:26px">
                                   
                                    <actionbutton :url="String(row.link)" buttontext="Go to site" v-if="row.link!=''"></actionbutton>
                                </div> -->
                            </div>
                        </div>
                        



                  
            
            </div>
      </div>
    </div>
</template>

<script>



    export default {
        name: "knowledgeview",
        data() {
            return {

                infotabledata: [],
                isloading: true,
                isvisible: 'None',
                componentinitialised: false,
                lastrowopened: -1
          
            }

        },
        mounted() {

            this.getData();

        },
 
        watch: { // watcher for tracking if any props change
            $props: {
            handler() {
           
                 this.getData();
            },
            deep: true,
            immediate: true,
            }
        },
  
        props: {
            view: {type:String, default: 'brief' }, //simple all entries view
            section: {type:String, default: 'all'}, 
            startdate: {type:Date,  default: function() {return new Date("1977/1/1")}}, //only show -2 days worth of stuff by default (for main screen horses mouth)
            enddate: {type:Date, default: function() {return new Date("2099/1/1")}},
            specifictext: {type:String, default: '%%'}
        },

        beforeDestroy() {
            clearInterval(this.timer);
        },

        methods: {

            showdetail: function(row) {

               // if (this.lastrowopened != -1 && this.detail[this.lastrowopened] == true && this.lastrowopened != row) {this.$set(this.detail, this.lastrowopened, false); document.getElementById('line' + row).innerText = "Detail ▴";}

                if (document.getElementById('blogdetail' + row).style.display == "none") {
                    document.getElementById('blog' + row).innerText = "▴";
                    document.getElementById('blogdetail' + row).style.display = "block";
                } else {
                    document.getElementById('blog' + row).innerText = "▾";
                    document.getElementById('blogdetail' + row).style.display= "none";    
                }

            }, 

            // get data for Most Popular
            getData: function () {


                let ksection = 'All';

                //spinner whilst loads
                this.isloading = true;
                this.isvisible = "None";  
               
                //variable cleansing
                if (this.section != '') {
                    ksection = this.section; // + '/';
                    ksection = ksection.toLowerCase();
                }
                

                let url = process.env.VUE_APP_WEBAPI  + "FeatureTables.svc/RetrieveGeneric";
                let spname = "GetKnowledgeBase_NS";
               let params = '[{ "Name": "Contains", "Value": "' + this.specifictext + '"}, { "Name": "KnowledgeType", "Value": "' +  ksection + '"}, {"Name": "StartDate", "Value": "' +  this.startdate.toISOString().slice(0, 10) + '"}, {"Name": "EndDate", "Value": "' + this.enddate.toISOString().slice(0, 10) + '" }]';
              
              
                //get bet results for that bet
                this.newgenericresultset(url, params,spname).then(response => { 

                    this.infotabledata = response
                
                    if (this.infotabledata.length==0) {
      
                    let img = require('/public/assets/icons/sleep.png') //convert image to binary representation
                        this.infotabledata = [{title: "No Knowledge or `Wisdom` is available for this subject", img: {filename: img}, desc: "There are no entries for you current selection", link: "#"}]     
                    } else {

                        //sort so by date created
                        this.infotabledata.sort((b, a) => a.created.localeCompare(b.created));
                    }

                    this.isloading = false;
                    this.isvisible = "block"; 
                                
                },
                    
                    (error) => { console.log(error) }
                );

 

                }
            }
        }
</script>


